import React from 'react';

export function Header() {
  return (
    <header
      className="uc-header header-five uc-navbar-sticky-wrap z-999 uc-sticky"
      data-uc-sticky="start: 100vh; show-on-up: true; animation: uc-animation-slide-top; sel-target: .uc-navbar-container; cls-active: uc-navbar-sticky; cls-inactive: uc-navbar-transparent; end: !*;"
    >
      <nav
        className="uc-navbar-container uc-navbar-float ft-tertiary z-1 uc-navbar-transparent"

      >
        <div className="uc-navbar-main" style={{ '--uc-nav-height': '64px' } as React.CSSProperties}>
          <div className="container">
            <div
              className="uc-navbar min-h-64px text-gray-900 dark:text-white"
              data-uc-navbar="animation: uc-animation-slide-top-small; duration: 150;"
            >
              <div className="uc-navbar-left">
                <div className="uc-logo">
                  <a className="panel text-none" href="/" style={{ width: '140px' }}>
                    <img className="dark:d-none" src="dist/assets/images/common/logo-xl.svg" alt="Piksera" />
                    <img className="d-none dark:d-block" src="dist/assets/images/common/logo-xd.svg" alt="Piksera" />
                  </a>
                </div>

                <ul className="uc-navbar-nav gap-3 d-none lg:d-flex ltr:ms-2 rtl:me-2">
                  <li>
                    <a href="#" role="button" aria-haspopup="true">
                      Products{' '}
                      <span data-uc-navbar-parent-icon="" className="uc-icon uc-navbar-parent-icon">
                        <svg width="12" height="12" viewBox="0 0 12 12">
                          <polyline fill="none" stroke="#000" strokeWidth="1.1" points="1 3.5 6 8.5 11 3.5"></polyline>
                        </svg>
                      </span>
                    </a>
                    {/* Products Dropdown */}
                    <div
                      className="uc-dropbar uc-dropbar-top p-0 ft-primary text-unset fs-6 fw-normal hide-scrollbar border-top rounded-0 overflow-hidden shadow-xl bg-white dark:bg-gray-900 uc-drop"
                      data-uc-drop="offset: 0; boundary: true; stretch: x; animation: uc-animation-slide-top-small; animate-out: uc-animation-slide-top-small; duration: 150;"
                      style={{ width: '1512px', overflowX: 'auto', maxWidth: '1512px', top: '64px', left: '-112px' }}
                    >
                      <div className="uc-dropbar-content">
                        <div className="container">
                          <div className="uc-dropbar-inner after-bg">
                            <div className="row gx-5 col-match justify-between">
                              <div className="col-8">
                                <div className="panel vstack gap-4 py-4">
                                  <div className="panel vstack gap-4">
                                    <div className="vstack gap-narrow">
                                      <h5 className="h5 xl:h4 fw-medium m-0">Piksera's AI Automation Platform</h5>
                                      <p className="fs-7 text-gray-300">AI-Powered Automation across 500+ apps</p>
                                    </div>

                                    <div className="row child-cols-6 gx-6">
                                      {[
                                        { name: 'Email Template Builder', description: 'Design personalized emails that convert', icon: 'icon-01.svg' },
                                        { name: 'Landing Page Builder', description: 'Deliver the right message to the right user', icon: 'icon-02.svg' },
                                        { name: 'Reporting & analytics', description: 'Track sales & campaign performance', icon: 'icon-03.svg' },
                                        { name: 'Chatbots, Free forever', description: 'Automate customer engagement', icon: 'icon-07.svg' },
                                      ].map((product, index) => (
                                        <div key={index}>
                                          <a
                                            href="/features"
                                            className="hstack items-start gap-2 text-none text-dark dark:text-white hover:text-primary dark:hover:text-tertiary"
                                          >
                                            <span className="icon rounded dark:bg-white">
                                              <img className="w-32px" src={`/dist/assets/images/custom-icons/${product.icon}`} alt="icon" />
                                            </span>
                                            <div className="panel">
                                              <span className="fs-7 fw-medium mb-narrow text-inherit">{product.name}</span>
                                              <p className="fs-8 text-muted">{product.description}</p>
                                            </div>
                                          </a>
                                        </div>
                                      ))}
                                      <div>
                                        <a
                                          href="/features"
                                          className="ltr:ms-6 rtl:me-6 text-none fs-8 text-dark dark:text-white hover:text-primary dark:hover:text-tertiary"
                                        >
                                          <span className="border-bottom hover:border-primary duration-150">View all products</span>
                                          <i className="fs-8 unicon-arrow-up-right fw-bold"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-4">
                                <div className="panel vstack gap-4 p-5 bg-gray-25 dark:bg-gray-800 border-start">
                                  <h5 className="h6">Get started is Easy!</h5>
                                  <ul className="uc-nav uc-navbar-dropdown-nav fs-7 fw-normal row child-cols-12">
                                    <li><a href="/contact">Hire an Expert</a></li>
                                    <li><a href="#">Customer stories</a></li>
                                    <li><a href="#">Resources</a></li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="uc-dropbar-footer py-2 xl:py-3 border-top bg-white dark:bg-gray-900 dark:text-white">
                        <div className="container">
                          <ul className="nav-x gap-4 fs-8 fw-medium">
                            <li><a href="#"><i className="fs-8 unicon-api fw-bold"></i> Explore Apps</a></li>
                            <li><a href="#"><i className="fs-8 unicon-airplay fw-bold"></i> AI Solutions</a></li>
                            <li><a href="#"><i className="fs-8 unicon-cloud-lightning fw-bold"></i> Join Piksera Early Access</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li>
                    <a href="#" role="button" aria-haspopup="true">
                      Solutions{' '}
                      <span data-uc-navbar-parent-icon="" className="uc-icon uc-navbar-parent-icon">
                        <svg width="12" height="12" viewBox="0 0 12 12">
                          <polyline fill="none" stroke="#000" strokeWidth="1.1" points="1 3.5 6 8.5 11 3.5"></polyline>
                        </svg>
                      </span>
                    </a>
                    {/* Solutions Dropdown */}
                    <div
                      className="uc-dropbar uc-dropbar-top p-0 ft-primary text-unset fs-6 fw-normal hide-scrollbar border-top rounded-0 overflow-hidden shadow-xl bg-white dark:bg-gray-900 uc-drop"
                      data-uc-drop="offset: 0; boundary: true; stretch: x; animation: uc-animation-slide-top-small; animate-out: uc-animation-slide-top-small; duration: 150;"
                      style={{ overflowX: 'auto', top: '64px', left: '-112px', width: '1512px', maxWidth: '1512px' }}
                    >
                      <div className="uc-dropbar-content">
                        <div className="container">
                          <div className="uc-dropbar-inner after-bg">
                            <div className="row gx-5 col-match justify-between">
                              <div className="col-8">
                                <div className="panel vstack gap-4 py-4">
                                  <div className="vstack gap-narrow flex-none">
                                    <h5 className="h5 xl:h4 fw-medium m-0">Workflow quick-start automation guide</h5>
                                    <p className="fs-7 text-gray-300">How Piksera can help you automate your work</p>
                                  </div>
                                  <div className="row child-cols-4 gx-4 col-match justify-between">
                                    {/* Dropdown Content for Solutions */}
                                    <div>
                                      <h6 className="h6 mb-3"><i className="fs-7 unicon-glyph-square-fill fw-bold ltr:me-narrow rtl:ms-narrow"></i> Automation</h6>
                                      <ul className="uc-nav uc-navbar-dropdown-nav vstack gap-2">
                                        <li>
                                          <a className="hstack items-start gap-2" href="/features">
                                            <div className="cstack min-w-32px h-32px rounded bg-tertiary">
                                              <i className="icon-1 unicon-checkmark fw-bold text-primary"></i>
                                            </div>
                                            <span className="vstack gap-narrow mt-nnarrow">
                                              <b className="fw-medium dark:text-white">To-Do List</b>
                                              <span className="fs-8 text-muted">Managing multiple software and tools for different tasks.</span>
                                            </span>
                                          </a>
                                        </li>
                                        <li>
                                          <a className="hstack items-start gap-2" href="/features">
                                            <div className="cstack min-w-32px h-32px rounded bg-tertiary">
                                              <i className="icon-1 unicon-chart-pie fw-bold text-primary"></i>
                                            </div>
                                            <span className="vstack gap-narrow mt-nnarrow">
                                              <b className="fw-medium dark:text-white">Reports</b>
                                              <span className="fs-8 text-muted">The latest industry reports, updates and info.</span>
                                            </span>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                    {/* Teaming Solutions */}
                                    <div>
                                      <h6 className="h6 mb-3"><i className="fs-7 unicon-glyph-circle-fill fw-bold ltr:me-narrow rtl:ms-narrow"></i> Teaming</h6>
                                      <ul className="uc-nav uc-navbar-dropdown-nav vstack gap-2">
                                        <li>
                                          <a className="hstack items-start gap-2" href="/features">
                                            <div className="cstack min-w-32px h-32px rounded bg-tertiary">
                                              <i className="icon-1 unicon-chat fw-bold text-primary"></i>
                                            </div>
                                            <span className="vstack gap-narrow mt-nnarrow">
                                              <b className="fw-medium dark:text-white">Chatbots</b>
                                              <span className="fs-8 text-muted">Automate customer engagement and support.</span>
                                            </span>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Sidebar with Image */}
                              <div className="col-4">
                                <div className="panel vstack gap-4 p-5 bg-gray-25 dark:bg-gray-800 border-start">
                                  <h5 className="h6">Start with ease!</h5>
                                  <ul className="uc-nav uc-navbar-dropdown-nav fs-7 fw-normal row child-cols-12">
                                    <li><a href="/contact">Hire an Expert</a></li>
                                    <li><a href="#">Customer stories</a></li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li><a href="/insights">Insights</a></li>
                  <li><a href="/pricing">Pricing</a></li>
                  <li>
                    <a href="https://piksera.net/">
                      <i className="icon unicon-cube fw-bold ltr:me-narrow rtl:ms-narrow text-primary dark:text-tertiary"></i>
                      Marketplace
                    </a>
                  </li>
                </ul>
              </div>

              {/* Navbar Right Section */}
              <div className="uc-navbar-right">
                <ul className="nav-x d-none lg:d-flex">
                  <li className="d-none xl:d-inline-flex"><a href="/contacts">Contact sales</a></li>
                  <li><a href="/login">Log in</a></li>
                </ul>
                <a
                  className="btn btn-sm btn-primary text-tertiary dark:bg-tertiary dark:text-primary dark:hover:bg-tertiary fw-bold rounded-pill lg:px-2 text-none hover:contrast-shadow d-none lg:d-inline-flex"
                  href="/register"
                >
                  Sign up
                </a>
                <a
                  className="d-block lg:d-none uc-icon uc-navbar-toggle-icon"
                  href="#uc-menu-panel"
                  data-uc-navbar-toggle-icon
                  data-uc-toggle
                  role="button"
                  aria-label="Open menu"
                >
                  <svg width="20" height="20" viewBox="0 0 20 20">
                    <rect className="line-1" y="3" width="20" height="2"></rect>
                    <rect className="line-2" y="9" width="20" height="2"></rect>
                    <rect className="line-3" y="9" width="20" height="2"></rect>
                    <rect className="line-4" y="15" width="20" height="2"></rect>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
