import { Fragment, useEffect, useState } from 'react';
import { Header } from './header';
import {Button, ButtonProps} from '@common/ui/buttons/button';
import {MixedImage} from '@common/ui/images/mixed-image';
import {Footer} from '@common/ui/footer/footer';
import {Trans} from '@common/i18n/trans';


export function LandingPage() {
  const [loading, setLoading] = useState(true); // Track if page is still loading

  useEffect(() => {
    // Helper to load CSS dynamically
    const loadCSS = (href: string) => {
      return new Promise<void>((resolve) => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = href;
        link.type = 'text/css';
        link.className = 'dynamic-style';
        link.onload = () => resolve();
        document.head.appendChild(link);
      });
    };

    // Helper to load JavaScript dynamically
    const loadScript = (src: string) => {
      return new Promise<void>((resolve) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.className = 'dynamic-script';
        script.onload = () => resolve();
        document.body.appendChild(script);
      });
    };

    // List of CSS and JS files to load
    const cssFiles = [
      '/dist/assets/css/unicons.min.css',
      '/dist/assets/css/swiper-bundle.min.css',
      '/dist/assets/css/magic-cursor.min.css',
      '/dist/assets/css/prettify.min.css',
      '/dist/assets/css/theme/theme-three.min.purge.css',
      '/dist/assets/js/uni-core/css/uni-core.min.css'
    ];

    const scriptFiles = [
      '/dist/assets/js/libs/jquery.min.js',
      '/dist/assets/js/libs/scrollmagic.min.js',
      '/dist/assets/js/libs/swiper-bundle.min.js',
      '/dist/assets/js/libs/anime.min.js',
      '/dist/assets/js/libs/typed.min.js',
      '/dist/assets/js/libs/tilt.min.js',
      '/dist/assets/js/libs/split-type.min.js',
      '/dist/assets/js/libs/prettify.min.js',
      '/dist/assets/js/libs/gsap.min.js',
      '/dist/assets/js/core/magic-cursor.js',
      '/dist/assets/js/helpers/data-attr-helper.js',
      '/dist/assets/js/helpers/swiper-helper.js',
      '/dist/assets/js/helpers/splitype-helper.js',
      '/dist/assets/js/helpers/anime-helper.js',
      '/dist/assets/js/helpers/typed-helper.js',
      '/dist/assets/js/helpers/tilt-helper.js',
      '/dist/assets/js/core/marquee.js',
      '/dist/assets/js/uikit-components-bs.js',
      '/dist/assets/js/app.js'
    ];

    // Load all CSS and JS files and set loading to false once done
    Promise.all([
      ...cssFiles.map(loadCSS),
      ...scriptFiles.map(loadScript)
    ]).then(() => setLoading(false));

    return () => {
      // Cleanup CSS and JS files on unmount
      document.querySelectorAll('.dynamic-style').forEach(link => link.remove());
      document.querySelectorAll('.dynamic-script').forEach(script => script.remove());
    };
  }, []);

  return (
    <Fragment>
      {loading ? (
        <div className="preloader">
          {/* Preloader styling or animation can be customized here */}
          <div className="spinner">Loading...</div>
        </div>
      ) : (
        <>
          <Header />
          <div className="landing-page">
            <HeroHeaderSection />
            <KeyFeaturesSection />

            <Footer />

          </div>

        </>
      )}
    </Fragment>
  );
}

// Preloader styles for spinner
const preloaderStyle = `
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white; /* Adjust color as needed */
  z-index: 9999;
}

.spinner {
  font-size: 1.5em;
  color: #007bff; /* Loader color */
  animation: spinner-animation 1s linear infinite;
}

@keyframes spinner-animation {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
`;

// Append the style directly to the document head
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = preloaderStyle;
document.head.appendChild(styleSheet);


function HeroHeaderSection() {
  return (
    <div id="hero_header" className="hero-header hero-seven-scene section panel overflow-hidden">
      <div className="position-absolute top-0 start-0 end-0 h-screen bg-tertiary-100 dark:bg-primary-900"></div>
      <div className="position-absolute top-0 start-0 end-0 h-screen bg-gradient-to-b from-transparent via-transparent to-white dark:to-black"></div>
      <div className="section-outer panel pb-6 sm:pb-8 pt-9 xl:pt-10 xl:pb-9">
        <div className="d-none lg:d-block">
          {/* Vector images */}
          <img src="dist/assets/images/vectors/marketing.svg" alt="Icon" className="d-inline-block position-absolute w-72px dark:d-none" style={{ top: '15%', left: '10%' }} />
          <img src="dist/assets/images/vectors/charts-pc.svg" alt="Icon" className="d-inline-block position-absolute w-72px dark:d-none" style={{ top: '15%', right: '10%' }} />
          <img src="dist/assets/images/vectors/group.svg" alt="Icon" className="d-inline-block position-absolute w-64px dark:d-none" style={{ top: '35%', right: '-1%', transform: 'rotate(45deg)' }} />
          <img src="dist/assets/images/vectors/idea.svg" alt="Icon" className="d-inline-block position-absolute w-48px dark:d-none" style={{ top: '40%', left: '15%' }} />
          <img src="dist/assets/images/vectors/group.svg" alt="Icon" className="d-inline-block position-absolute w-64px dark:d-none" style={{ top: '30%', left: '-1%' }} />
          <img src="dist/assets/images/vectors/marketing-dark.svg" alt="Icon" className="d-inline-block position-absolute w-72px d-none dark:d-block" style={{ top: '15%', left: '10%' }} />
          <img src="dist/assets/images/vectors/charts-pc-dark.svg" alt="Icon" className="d-inline-block position-absolute w-72px d-none dark:d-block" style={{ top: '15%', right: '10%' }} />
          <img src="dist/assets/images/vectors/group-dark.svg" alt="Icon" className="d-inline-block position-absolute w-64px d-none dark:d-block" style={{ top: '35%', right: '-1%', transform: 'rotate(45deg)' }} />
          <img src="dist/assets/images/vectors/idea-dark.svg" alt="Icon" className="d-inline-block position-absolute w-48px d-none dark:d-block" style={{ top: '40%', left: '15%' }} />
          <img src="dist/assets/images/vectors/group-dark.svg" alt="Icon" className="d-inline-block position-absolute w-64px d-none dark:d-block" style={{ top: '30%', left: '-1%' }} />
        </div>

        <div className="container max-w-xl">
          <div className="section-inner panel">
            <div className="row child-cols-12 justify-center items-center g-8">
              <div className="lg:col-8">
                <div className="panel vstack items-center gap-2 px-2 text-center">
                  <span className="fs-7 fw-bold py-narrow px-2 border rounded-pill text-primary dark:text-tertiary">Be Creative</span>
                  <h1 className="h3 sm:h2 md:h1 lg:display-6 lh-lg mb-1 xl:mb-2 mt-2">
                    Get more done in less time with{' '}
                    <span className="px-1 bg-primary text-tertiary dark:bg-tertiary dark:text-primary" data-uc-typed="typeSpeed: 80; backSpeed: 50; backDelay: 1500; loop: true;">
                      <span>No Code Builder</span>
                      <span>Pre-Built</span>
                      <span>Premier Templates</span>
                    </span>
                  </h1>
                  <p className="fs-6 xl:fs-3 xl:px-6">
                    Unlock your <b className="dark:text-white">productivity</b> potential and automate as fast as you can type with our intuitive and powerful{' '}
                    <b className="dark:text-white">Web</b> application.
                  </p>
                  <div className="vstack md:hstack justify-center gap-2 mt-3">
                    <a href="/register" className="btn btn-md xl:btn-lg btn-alt-dark border-dark px-3 lg:px-5 fw-bold contrast-shadow-sm hover:contrast-shadow">
                      <img src="dist/assets/images/template/google-icon.svg" alt="Google Icon" />
                      <span>Start free trial</span>
                    </a>
                  </div>

                  <div className="panel mt-3 lg:mt-4 min-w-700px text-center">
                    <div className="row child-cols-12 lg:child-cols-4 justify-center gx-0">
                      <div>
                        <div className="hstack justify-center gap-1">
                          <span className="icon mb-narrow">
                            <img className="w-24px dark:d-none" src="dist/assets/images/custom-icons/icon-08.svg" alt="icon" />
                            <img className="w-24px d-none dark:d-block" src="dist/assets/images/custom-icons/dark-icon-08.svg" alt="icon" />
                          </span>
                          <span className="fs-7 fw-medium mb-narrow text-inherit">Free Website Builder</span>
                        </div>
                      </div>
                      <div>
                        <div className="hstack justify-center gap-1">
                          <span className="icon mb-narrow">
                            <img className="w-24px dark:d-none" src="dist/assets/images/custom-icons/icon-07.svg" alt="icon" />
                            <img className="w-24px d-none dark:d-block" src="dist/assets/images/custom-icons/dark-icon-07.svg" alt="icon" />
                          </span>
                          <span className="fs-7 fw-medium mb-narrow text-inherit">Chatbots, Free forever</span>
                        </div>
                      </div>
                      <div>
                        <div className="hstack justify-center gap-1">
                          <span className="icon mb-narrow">
                            <img className="w-24px dark:d-none" src="dist/assets/images/custom-icons/icon-04.svg" alt="icon" />
                            <img className="w-24px d-none dark:d-block" src="dist/assets/images/custom-icons/dark-icon-04.svg" alt="icon" />
                          </span>
                          <span className="fs-7 fw-medium mb-narrow text-inherit">HTML & Laravel</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="uc-video-scene">
                <div className="panel max-w-1000px mx-auto mt-2 rounded lg:rounded-1-5 xl:rounded-2 border border-dark contrast-shadow-lg overflow-hidden">
                  <video preload="auto" playsInline muted loop poster="dist/assets/images/media/lexend-home-7.png" src="/dist/assets/images/media/lexend-home-7.mp4" title="Video title" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );


}

function KeyFeaturesSection() {
  return (
    <div id="key_features" className="key-features section panel overflow-hidden uc-dark lg:px-6">
      <div
        className="section-outer panel py-4 md:py-6 xl:py-10 bg-secondary dark:bg-primary-700 dark:text-white dark:text-opacity-60 rounded-2 lg:rounded-4"
        data-anime="onscroll: .key-features; onscroll-trigger: 1; onscoll-duration: 150%; translateY: [-80, 0]; scale: [0.85, 1]; opacity: [0.85, 1]; easing: linear;"
      >
        <img
          className="d-none lg:d-inline-block w-200px position-absolute"
          src="dist/assets/images/vectors/man-trigger.svg"
          alt="man-trigger"
          style={{ bottom: '-5%', left: '20%' }}
          data-anime="onview: -200; scale: [0.8, 1]; opacity: [0, 1]; easing: easeOutCubic; duration: 500; delay: 500;"
        />
        <img
          className="d-none lg:d-inline-block w-32px position-absolute text-white"
          src="dist/assets/images/vectors/appostrof.svg"
          alt="appostrof"
          style={{ bottom: '16%', right: '33%', transform: 'rotate(45deg)' }}
          data-uc-svg
          data-anime="onview: -200; scale: [0.8, 1]; opacity: [0, 1]; easing: easeOutCubic; duration: 500; delay: 500;"
        />
        <div className="container sm:max-w-lg">
          <div className="section-inner panel">
            <div
              className="panel vstack items-center gap-2 xl:gap-3 mb-4 sm:mb-6 lg:mb-8 sm:max-w-600px lg:max-w-700px xl:max-w-800px mx-auto text-center"
              data-anime="onview: -200; targets: >*; translateY: [48, 0]; opacity: [0, 1]; easing: easeOutCubic; duration: 500; delay: anime.stagger(100, {start: 200});"
            >
              <span className="fs-7 fw-medium py-narrow px-2 border rounded-pill text-primary dark:text-tertiary">Solutions</span>
              <h2 className="h3 lg:h2 m-0">
                <span className="px-1 bg-tertiary text-primary">AI-Powered</span> Automation Solutions
              </h2>
              <p className="fs-6 xl:fs-3 xl:px-8">
                Offers a unified platform that fosters innovation while providing end-to-end data management.
              </p>
            </div>

            {/* Individual Feature Items */}
            <div
              className="features-items row child-cols-12 sm:child-cols-6 lg:child-cols-4 g-4 lg:g-6 col-match"
              data-anime="onview: -200; targets: >*; translateY: [48, 0]; opacity: [0, 1]; easing: easeOutCubic; duration: 500; delay: anime.stagger(100, {start: 400});"
            >
              {/* Feature 1 */}
              <div className="order-1">
                <div className="features-item vstack items-center justify-center text-center gap-4">
                  <div className="icon-box cstack w-48px h-48px dark:bg-tertiary rounded">
                    <img className="w-24px xl:w-32px text-primary-400 dark:text-tertiary" src="dist/assets/images/custom-icons/icon-01.svg" alt="feature-icon" />
                  </div>
                  <div className="panel">
                    <div className="vstack gap-1">
                      <h3 className="title h6 m-0">Fast and Reliable</h3>
                      <p className="desc fs-6 opacity-60 dark:opacity-90">
                        Whether you have a team of 2 or 200, our shared team inboxes keep everyone on the same page and in the loop.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Feature 2 */}
              <div className="order-2">
                <div className="features-item vstack items-center justify-center text-center gap-4">
                  <div className="icon-box cstack w-48px h-48px dark:bg-tertiary rounded">
                    <img className="w-24px xl:w-32px text-primary-400 dark:text-tertiary" src="dist/assets/images/custom-icons/icon-02.svg" alt="feature-icon" />
                  </div>
                  <div className="panel">
                    <div className="vstack gap-1">
                      <h3 className="title h6 m-0">Discover Data Everywhere</h3>
                      <p className="desc fs-6 opacity-60 dark:opacity-90">
                        Whether you have a team of 2 or 200, our shared team inboxes keep everyone on the same page and in the loop.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Feature 3 */}
              <div className="order-3">
                <div className="features-item vstack items-center justify-center text-center gap-4">
                  <div className="icon-box cstack w-48px h-48px dark:bg-tertiary rounded">
                    <img className="w-24px xl:w-32px text-primary-400 dark:text-tertiary" src="dist/assets/images/custom-icons/icon-03.svg" alt="feature-icon" />
                  </div>
                  <div className="panel">
                    <div className="vstack gap-1">
                      <h3 className="title h6 m-0">Enrich Data with Context</h3>
                      <p className="desc fs-6 opacity-60 dark:opacity-90">
                        Whether you have a team of 2 or 200, our shared team inboxes keep everyone on the same page and in the loop.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Feature 4 */}
              <div className="order-4">
                <div className="features-item vstack items-center justify-center text-center gap-4">
                  <div className="icon-box cstack w-48px h-48px dark:bg-tertiary rounded">
                    <img className="w-24px xl:w-32px text-primary-400 dark:text-tertiary" src="dist/assets/images/custom-icons/icon-07.svg" alt="feature-icon" />
                  </div>
                  <div className="panel">
                    <div className="vstack gap-1">
                      <h3 className="title h6 m-0">Risk Management</h3>
                      <p className="desc fs-6 opacity-60 dark:opacity-90">
                        Whether you have a team of 2 or 200, our shared team inboxes keep everyone on the same page and in the loop.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Call-to-action */}
            <div
              className="pre-cta vstack items-center gap-1 max-w-400px lg:max-w-750px mx-auto text-center mt-6 xl:mt-10"
              data-anime="onview:-100; targets: >*; translateY: [48, 0]; opacity: [0, 1]; easing: easeOutCubic; duration: 500; delay: anime.stagger(100, {start: 200});"
            >
              <h2 className="h4 xl:h3 m-0">Say goodbye to the hassle of multi-tasks</h2>
              <p className="fs-6 sm:fs-5 text-dark dark:text-white text-opacity-70">
                Managing multiple tasks with AI automation.
              </p>
              <div className="vstack md:hstack justify-center gap-2 mt-3">
                <a href="#" className="btn btn-md xl:btn-lg btn-alt-dark border-dark px-3 lg:px-5 fw-bold contrast-shadow-sm hover:contrast-shadow">
                  <img src="dist/assets/images/template/google-icon.svg" alt="Google Icon" />
                  <span>Start free trial</span>
                </a>
              </div>
              <span className="fs-7 mt-1">No credit card required!</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
